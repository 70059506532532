.main-menu-bar{
    padding: 11px 15px;
    height: 86px;
    width: 100%;
    display: flex;
    justify-content: center;

    .logo{
        font-size: 64px;
        color: var(--primary-text-color);
    }

    &.logo-off{
       .logo{
           display: none;
       }
    }

    .container{
        display: flex;

        & > .right-side,
        & > .left-side{
            display: flex;
            align-items: center;
        }

        & > .right-side{
            justify-content: flex-end;

            &  > * {
                display: flex;
                align-items: center;

                & + *{
                    margin-left: 12px;
                }
            }
        }

        & > .left-side{
            flex: 1;
        }
    }
}

