.photo-upload{
    display: block;
    margin: 10px 0 0;

    .ant-upload{
        display: flex;
        align-items: center;

        & > .ant-btn{
            margin-left: 20px;
        }
    }

    &.contain{
        .ant-avatar > img{
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}