.activation-account{
    .loader-item{
        margin-bottom: 20px;
    }

    h1{
        margin-bottom: 20px;
    }

    .link{
        &-active{
            margin-top: 30px;
            display: block;
        }

        &-button-primary{
            margin-top: 50px;
        }
    }
}