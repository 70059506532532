@import 'scss/vendors/include-media';

.visit-checkin{
    &-form{
        text-align: left;
    }

    &-step{
        margin-top: 24px;

        @include media('height<=540px') {
            .form-actions{
                position: initial !important;
                margin: 0 !important;
            }
        }

        @include media('<=phone') {
            .form-actions{
                position: fixed;
                bottom: 20px;
                margin: 0 20px;
                left: 0;
                right: 0;

                .ant-btn{
                    width: 100%;
                }
            }
        }

        &.guests{
            .row{
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity20)) ;
                padding: 12px 0;
                margin: 0 !important;
                font-size: 16px;


                .anticon{
                    font-size: 24px;
                    margin-right: 24px;
                }
            }

            .form-actions{
                margin-top: 40px;
            }

            .guest-input{
                display: flex;
                align-items: center;


                .ant-btn{
                    width: 40px;
                    height: 40px;
                }

                .label-wrap{
                    margin: 0 10px;
                }

                .ant-input{
                    width: 60px;
                    text-align: center;
                    padding-top: 5px !important;
                }
            }
        }

        &.legal{

            .ant-checkbox{
                transform: translate(0px, 12px);

                &-wrapper{
                    p{
                        margin-top: 10px !important;
                    }
                }
            }

            .form-actions{
                margin-top: 40px;
            }

        }

        &.success{

            .avatar-block{
                margin: 24px 0;
            }

            .bordered-top{
                text-align: center;

                .link-button-primary{
                    margin-top: 10px;
                }
            }
        }

    }
}

