@import 'scss/vendors/include-media';

.menu-drawer{

    .ant-drawer{
        &-content-wrapper{
            border-radius: 80px 80px 0 0;
            overflow: hidden;
            min-height: 100% !important;
            transition: all var(--animation-speed) ease-out;
        }

        &-wrapper-body{
            min-height: 500px;
            padding: 80px 24px 90px;
            background-color: var(--secondary-bg-color);
            position: relative;
        }

        &-body{
            height: 100%;
            padding: 0;


            .container{
                height: 100%;
            }
        }

        &-close{
            font-size: 32px;
            color: var(--secondary-text-color);
            top: 15px;
            right: 5px;
            padding: 10px !important;

            &:hover{
                color: var(--secondary-button-color)
            }
        }
    }

    .qr-code-modal-button-wrap{
        color: var(--secondary-text-color);
        bottom: 30%;
        font-size: 18px;
        max-width: 200px;

        @include media('height<=605px') {
            bottom: 15%;
            position: absolute;
        }
    }

    &.ant-drawer{
        &-open{
            .ant-drawer-content-wrapper{
                border-radius: 0 !important;
            }
        }
    }
}