.visit-list{

    &-item{
        margin-top: 10px;
        padding: 12px 0;
        border-top: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity20));

        &:first-child{
            margin-top: 0;
            border: none;
        }
    }

    &-logo{
        .ant-avatar{
            padding: 10px;
        }

        .avatar-image + .avatar-content{
            margin-left: 16px;
        }

        .avatar-content{
            h3{
                margin: 4px 0 2px;
                line-height: 1.33em;
            }

            .details{
                margin-top: 4px;
                display: flex;

                & > span{
                    display: flex;
                    align-items: center;

                    & + span{
                        margin-left: 20px;
                    }
                }

                .anticon{
                    font-size: 24px;
                    margin-right: 8px;
                }
            }
        }
    }
}
