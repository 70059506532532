.visits-page{
    .block{
        top: 0 !important;
    }
}

.location-page{
    .block{
        top: 0 !important;
    }
}