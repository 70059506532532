.legal-menu{
    display: flex;
    justify-content: center;

    &-wrap{
        max-width: 300px;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    a{
        display: block;
        padding: 5px;
        color: var(--secondary-text-color);

        &:hover{
            color: var(--active-text-color);
        }
    }
}