$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';

:root {

    --primary-bg-color: #{hexToStr($background)};

    --secondary-bg-color: #{hexToStr($dark-green)};
    --secondary-bg-color-rgb: #{hexToRGB($dark-green)};

    --primary-text-color: #{hexToStr($dark-green)};
    --primary-text-color-rgb: #{hexToRGB($dark-green)};

    --secondary-text-color: #{hexToStr($white)};

    --active-text-color: #{hexToStr($orange)};

    --primary-button-bg-color: #{hexToStr($orange)};
    --primary-button-bg-color-rgb: #{hexToRGB($orange)};
    --active-button-bg-color: #{hexToStr($orange-red)};

    --primary-button-color: #{hexToStr($white)};
    --secondary-button-color: #{hexToStr($orange)};
    --active-button-color: #{hexToStr($orange-red)};

    --error-color:  #{hexToStr($error)};
    --success-color:  #{hexToStr($success)};
    --warning-color:  #{hexToStr($warning)};

    --opacity60: 0.6;
    --opacity50: 0.5;
    --opacity20: 0.2;

    --animation-speed: 0.3s;
}

body,
p{
    margin: 0;
}

ul{
    @extend %list-reset;
}

html, body, #root{
    min-height: 100%;
}

body{
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    font: 400 $primary-font-size $primary-font;
    line-height: $primary-line-height;
}

.guest-app,
.app{
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    &.ios{
        //noinspection CssInvalidPropertyValue
        min-height: -webkit-fill-available;
    }
}

h1{
    color: var(--primary-text-color);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4em;
}

h3{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33em;
    color: var(--primary-text-color);
}

.color{

    &-primary{
        color: var(--primary-text-color);
    }
}

.text{
    color: var(--primary-text-color);

    &-bold{
        @extend .text;
        font-weight: bold;
    }

    &-light{
        color: rgba(var(--primary-text-color-rgb),  var(--opacity60));
    }

    &-white{
        color: var(--secondary-text-color);
    }

    &-error{
        color: var(--error-color);
    }

    &-type2{
        @extend .text;
        font-size: 14px;
        line-height: 1.29em;
    }

    &-type3{
        @extend .text;
        font-size: 12px;
        line-height: 1.33em;

        &-light{
            @extend .text-type3, .text-light;
        }

        &-error{
            @extend .text-type3, .text-error;
        }
    }

    &-mono{
        color: rgba(var(--primary-text-color-rgb),  var(--opacity50));
        font: 400 14px/1.14em $secondary-font;
        letter-spacing: 1px;

        &-bold{
            @extend .text-mono;
            font-weight: bold;
        }
    }
}

.link{

    &-text{
        @extend .text;

        &:hover{
            color: rgba(var(--primary-text-color-rgb),  var(--opacity60));
        }

        &:focus{
            @extend .text;
        }

        &-bold{
            @extend .link-text, .text-bold;
        }
    }

    &-active{
        color: var(--secondary-button-color);

        &:hover{
            color: var(--active-button-color);
        }
    }

    &-button{
        @extend .ant-btn, %transition;
        display: inline-flex;
        align-items: center;
        justify-content: center;


        &-primary{
            @extend  .link-button, .ant-btn-primary;

            &:hover{
                color: var(--primary-button-color);
            }
        }

        &-default{
            @extend  .link-button, .ant-btn-default;
            padding: 0 30px;

            &:hover{
                color: var(--primary-button-color);
            }
        }


    }
}

.ant-spin{
    color: inherit !important;
}

.ant-btn{
    @extend .text-mono-bold;
    height: 48px;
    min-width: 130px;
    padding: 0 30px;
    text-transform: uppercase;
    box-shadow: initial !important;
    background-color: transparent;
    border: none;

    &:hover{
        background-color: transparent;
    }

    &-link{
        @extend .link-text;
        font-size: 16px;
        color: var(--primary-text-color);
        font-family: inherit !important;
        height: auto;
        width: auto !important;
        min-width: initial !important;
        padding: 0;
        letter-spacing: initial;
        text-transform: initial;

    }

    &.icon{
        font-size: 24px;
        height: auto;
        width: auto;
        min-width: auto;
        padding: 5px;
        border-radius: 50%;
        color: var(--primary-text-color);

        &.rounded{
            @extend .ant-btn-default;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.rounded-primary{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary-button-bg-color) !important;
            color: var(--primary-button-color) !important;

            &:hover{
                background-color: var(--active-button-bg-color) !important;
                border-color: var(--active-button-bg-color) !important;
            }
        }

        .anticon{
            vertical-align: 0;
            line-height: 0;
        }

        &:focus{
            background-color: initial;
            color: var(--primary-text-color);
        }

        &:active {
            color: var(--primary-text-color);
            position: relative;
        }

        &:hover:not(:active){
            color: rgba(var(--primary-text-color-rgb),  var(--opacity60));
        }
    }

    &-default{
        @extend .ant-btn-primary;
        background-color: transparent !important;
        color: var(--secondary-button-color) !important;

        &:hover{
            color: var(--active-button-color) !important;
        }

        &:active,
        &:focus{
            @extend .ant-btn-default;
        }

    }

    &-primary{
        background-color: var(--primary-button-bg-color);
        color: var(--primary-button-color);
        border: 2px solid var(--primary-button-bg-color);

        &:active,
        &:focus{
            @extend .ant-btn-primary;
        }

        &:hover:not(:active){
            background-color: var(--active-button-bg-color);
            border-color: var(--active-button-bg-color);
        }

        &[disabled]:hover,
        &[disabled]{
            color: var(--primary-button-color);
            background-color: rgba(var(--primary-button-bg-color-rgb), var(--opacity50)) !important;
            border-color: rgba(var(--primary-button-bg-color-rgb), var(--opacity20)) !important;
        }

    }
}

.ant-form{
    [role="alert"]{
        @extend .text-type3-error;
        padding: 0 10px;
    }

    .field-group &-item{
        margin-bottom: 0;
    }

    .field-group,
    & *:not(.field-group) &-item{

        margin-bottom: 24px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .field-group{
        display: flex;

        & > *{
            display: flex;
            flex-direction: column;
            flex: 1;

            .ant-col-24{
                flex: initial;
            }
        }

        & > * + *{
            margin-left: 22px;
        }
    }

    .ant-form-item-has-error{

        .ant-input-password,
        .ant-input{
            border-color: var(--error-color) !important;
            border-width: 2px;
            box-shadow: none !important;
        }

        .ant-checkbox{
            .ant-checkbox-inner{
                border-color: var(--error-color) !important;
            }
        }
    }
    .ant-form-item{
        .ant-input-textarea-show-count:after {
            bottom: initial;
            top: -20px;
            font-weight: bold;
            font-size: 12px;
            font-family: $primary-font;
            right: 0;
        }
    }

    .ant-radio-group-outline{
        display: flex;
    }

    .label {
        @extend .text-light;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 13px;
        transition: var(--animation-speed) ease all;
        z-index: 10;

        &-wrap{
            position: relative;
            margin-bottom: 5px;

            .ant-input{
                padding-top: 20px !important;
            }
        }

        &-float {
            @extend .text-type3-light;
            top: 6px;
        }
    }
}

.ant-input{
    border: none;

    &textarea{
        padding: 6px 10px;
    }

    &-affix-wrapper,
    :not(#{&}-affix-wrapper) > & {
        border: none;
        border-bottom: 1px solid rgba(var(--secondary-bg-color-rgb),  var(--opacity20));
        height: 48px;
        border-radius: 0;

        &:hover {
            border-color: var(--secondary-bg-color);
            border-width: 2px !important;
        }

        &:focus,
        &-focused {
            box-shadow: none;
            @extend .ant-input:hover;
        }
    }

    &-affix-wrapper:not(&-affix-wrapper-disabled){
        &:hover{
            border-color: var(--secondary-bg-color);
            border-width: 2px !important;
        }
    }
}

.ant-checkbox{
    top: 0;

    &-input{
        &:focus{
            & + .ant-checkbox-inner{
                border-color: var(--primary-button-bg-color);
            }
        }
    }

    &-wrapper{
        @extend .text;
        display: flex;
        min-height: 24px;

        &:hover{
            .ant-checkbox-inner{
                border-color: rgba(var(--primary-button-bg-color-rgb), var(--opacity80)) !important;
                border-width: 1px;
            }
        }
    }

    &-inner{
        width: 24px;
        height: 24px;


        &:after{
            width: 9px;
            height: 15px;
        }
    }

    &-checked{

        &:after{
            border-color: var(--primary-button-bg-color);
        }

        .ant-checkbox-inner{
            background-color: var(--primary-button-bg-color);
            border-color: var(--primary-button-bg-color);
        }

    }

}

.steps-nav{
    display: flex;
    flex: 1;
    justify-content: center;

    &-step{
        @extend %transition;

        color: rgba(var(--secondary-bg-color-rgb), var(--opacity60));
        font-size: 22px;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

        &.active{
            @extend .steps-nav-step-bordered;
        }

        &.finished{
            color: var(--secondary-bg-color) !important;
        }

        &:first-child{
            margin: 0;
        }

        &-bordered{
            border: 2px solid var(--secondary-bg-color);
            color: var(--secondary-bg-color);
        }
    }

    &-wrap{
        display: flex;
        flex: 1;
    }

    &-back{
        min-width: 36px;

        button{
            @extend .steps-nav-step, .steps-nav-step-bordered;
            background-color: transparent;
            padding: 0;
            cursor: pointer;

            &:hover{
                border-color: var(--primary-button-bg-color);
                color: var(--secondary-button-color);
            }

            .anticon{
                transform: rotate(90deg);
                font-size: 26px;
            }
        }

    }

}


.modal{

    &-full{
        max-width: initial!important;
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;

        .actions{
            margin-top: 40px;
            display: flex;
            justify-content: center;

            button{
                max-width: 320px;
                width: 100%;
            }
        }

        .container{
            max-width: 1024px;
            margin: 0 auto;
            padding: 90px 0;
        }
        h1{
            margin-bottom: 30px;
        }

        h3{
            margin-top: 30px;
            margin-bottom: 15px;
        }

        h4{
            margin-top: 25px;
            margin-bottom: 10px;
        }

        p + p{
            margin-top: 10px;
        }

        p + ul{
            margin-top: 10px;
            margin-left: 20px;
        }

        ul{
             ul{
                 margin-bottom: 15px;
                 li{
                     position: relative;
                     padding-left: 20px;
                     &:before{
                         left: 0;
                         position: absolute;
                         content : "-";
                     }
                 }
             }
        }

    }

    &-standard{
        min-width: 320px;
        max-width: 480px;
        width: 100% !important;
        margin: 0;
        padding: 0;

        @include media('height<=550px') {
            min-height: 530px;

            .form-actions{
                position: initial !important;
                margin: 0 !important;
            }
        }

        @include media('<=phone') {
            height: 100%;

            .form-actions{
                position: fixed;
                bottom: 25px !important;
                margin: 0 25px;

            }

        }
        //height: 100%;

        h1{
            margin-bottom: 30px;
        }

        .ant-form{

            .ant-form-item:last-child{
                margin-bottom: 0;
            }

            .form-actions{
                margin-top: 30px;
                display: flex;
                //position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                & > *{
                    flex: 1;
                }
            }
        }

        .ant-modal{

            &-content,
            &-body{
                height: 100%;
            }

            &-content{
                background-color: var(--primary-bg-color);
            }

            &-body{
                padding: 40px 25px 30px;
                position: relative;

                @include media('<=phone') {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            &-close-x{
                right: 0 !important;
                top: 0 !important;
                font-size: 28px;
            }
        }

    }

    &-dark{

        .ant-modal{
            &-close-x{
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 28px;
                color: var(--secondary-text-color) !important;
                @extend %transition;

                &:hover{
                    color: var(--active-text-color) !important;
                }
            }

            &-content{
                background-color: var(--secondary-bg-color) !important;
                color: var(--secondary-text-color) !important;

                h1, h4{
                    color: var(--secondary-text-color);
                }

                h3 {
                    color: var(--active-text-color);
                }

                a{
                    color: var(--secondary-text-color);
                    text-decoration: underline;

                    &:hover{
                        color: var(--active-text-color);
                    }
                }
            }
        }
    }
}

.icons-set{
    font-size: 32px;
    color: rgba(var(--primary-text-color-rgb),  var(--opacity50));
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .anticon{
        margin-top: 16px;

        &:first-child{
            margin-top: initial;
        }
    }
}

.loader{
    &-block{
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-page{

    }
}

.qr-code{
    &-modal{

        @include media('<=phone') {
            min-height: 450px !important;

            .ant-modal-body{
                padding: 0;

                .qr-code-reader-wrap{
                    flex: 1;
                }

            }
        }

        .close-button-wrap{
            margin-top: 40px;

            .ant-btn{
                width: 100%;
            }

            @include media('<=phone') {
                position: fixed;
                bottom: 20px;
                left: 20px;
                right: 20px;

            }

            @include media('height<=605px') {
                position: initial !important;
                margin: 20px;
            }
        }


        &-button-wrap{
            text-align: center;
            position: fixed;
            bottom: 30px;
            left: 50%;
            transform: translate(-50%, 0);

            @include media('height<=605px') {
                position: initial !important;
                transform: initial !important;
                margin: 20px auto;
            }

            button{
                margin: 5px auto;
                width: 55px !important;
                height: 55px !important;

                .anticon{
                    font-size: 30px;
                }
            }

        }
    }

    &-reader-wrap{
        padding-bottom: 90px;

        section{

            height: 100%;
            max-height: 600px;

            @include media('<=phone') {
                min-height: 300px;
                padding-top: initial !important;

                div{
                    border-top-width: 120px !important;
                    border-bottom-width: 120px !important;
                }
            }

            @include media('height<=605px') {
                height: 100%;

                div{
                    border-top-width: 50px !important;
                    border-bottom-width: 50px !important;
                }
            }
        }
    }
}

.page{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 25px;

    .block{
        position: relative;
        top: 15%;

        @include media('height<=605px') {
            top: initial;
        }
    }
}
