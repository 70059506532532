@import 'scss/vendors/include-media';

.location{
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media('>=phone') {
        align-items: center;
    }


    &-logo{
        position: relative;

        .avatar-image{
            position: absolute;
            bottom: -20px;
            left: 0;

            @include media('>=phone') {
                .ant-avatar {
                    width: 140px !important;
                    height: 140px !important;
                    line-height: 140px !important;
                }
            }
        }

        .avatar-content{
            margin: 0 auto !important;

            .banner{
                width: 240px;
                height: 240px;
                border-radius: 50%;
                overflow: hidden;

                @include media('>=phone') {
                    width: 350px;
                    height: 350px;
                }

            }

            img{
                object-fit: cover;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    .icons-set{
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translate(0, -50%);

        @include media('>=phone') {
            right: -50px;
        }

    }

    &-data{
        margin: 30px 0;
        width: 100%;

        @include media('>=phone') {
            margin-top: 50px;
        }

        .link-text{
            text-decoration: underline;
        }

        .group{
            & + .group{
                margin-top: 30px;
            }
        }
    }

    .link-button-primary{
        width: 100%;
        max-width: 320px;
        margin-bottom: 20px;

        @include media('<=phone') {
            position: fixed;
            bottom: 20px;
            max-width: calc(100% - 40px);
            left: 20px;
            right: 20px;
            margin: 0 auto;
        }

        @include media('height<=550px') {
            position: initial;

        }
    }
}