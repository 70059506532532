.profile-page{
    .user-info{
        display: flex;

        & > *:first-child{
            flex: 1;
        }

        .action{
            display: flex;
            align-items: center;
        }
    }
}